import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import ArrowIcon from "./ArrowIcon";

const ServicesGrid = ({ services }) => {
    return (
        <div className="service-grid">
            {services.map((item, i) => (
                <Link key={i} className="text-decor-none" to={`/services/${item.slug}/`}>
                    <div className="service-grid__item">
                        <div className="service-grid__item-image">
                            <GatsbyImage
                                image={item.cardBanner?.gatsbyImageData}
                                alt={item.cardBanner?.title}
                                className="services-image"
                                loading="lazy"
                            />
                        </div>
                        <div className="service-grid__item-content">
                            <h3 className=" service-grid__item-content__title text-p3 text-clr-primary text-fw-medium">
                                {item.title}
                            </h3>
                            <p className="service-grid__item-content__description text-p3 text-clr-secondary text-fw-regular">
                                {item.description?.description}
                            </p>
                            <div className="arrow">
                                <ArrowIcon direction="right" theme={"blue"} />
                            </div>
                        </div>
                    </div>
                </Link>
            ))}
        </div>
    );
};

export default ServicesGrid;
