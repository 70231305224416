import React from "react";
import Layout from "../../components/Layout";
import Seo from "../../components/seo";
import PartnerBannerImage from "../../images/partners.png";
import Testimonials from "../../components/Testimonials";
import { useStaticQuery, graphql, Link } from "gatsby";
import ServicesGrid from "../../components/ServicesGrid";
import { StaticImage } from "gatsby-plugin-image";
import ArrowIcon from "../../components/ArrowIcon";
import ContactUsBlock from "../../components/ContactUsBlock";
import ogIMage from "../../images/og.png";
import PartnerInfo from "../../components/Partner-Info";
const PartnerPage = ({ location }) => {
    const partnerDetails = useStaticQuery(graphql`
        {
            allContentfulTestimonials {
                nodes {
                    id
                    personDesignation
                    personName
                    profileImage {
                        gatsbyImageData(
                            quality: 100
                            sizes: "250"
                            layout: CONSTRAINED
                            placeholder: BLURRED
                        )
                        title
                    }
                    testimonialType
                    testimonialStatement {
                        testimonialStatement
                    }
                }
            }
            allContentfulServices {
                nodes {
                    title
                    slug
                    description {
                        description
                    }
                    heroBanner {
                        title
                        gatsbyImageData(
                            quality: 100
                            width: 384
                            placeholder: BLURRED
                            layout: CONSTRAINED
                        )
                    }
                    cardBanner {
                        title
                        gatsbyImageData(
                            quality: 100
                            width: 384
                            placeholder: BLURRED
                            layout: CONSTRAINED
                        )
                    }
                }
            }
        }
    `);
    const NavbarTheme = {
        color: "light",
        paddingTop: "0px",
    };

    const clientTestimonils = partnerDetails.allContentfulTestimonials.nodes.filter(
        (el) => !el.testimonialType
    );

    return (
        <Layout NavbarTheme={NavbarTheme}>
            <Seo
                title="Partners - Sedin"
                description="Discover about our technology partners, we’ve partnered with leading companies leaders to deliver future-ready technology solutions to drive better business outcomes."
                url={location.href}
                image={`https://sedintechnologies.com${ogIMage}`}
            />
            <>
                <section
                    className="partner-hero "
                    style={{
                        backgroundImage: `url(${PartnerBannerImage})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center center",
                    }}
                >
                    <div className="container container--banner">
                        <div className="partner-hero__text">
                            <h1 className="text-p1 partner-hero__text-title text-fw-regular gap-2x">
                                Our partners
                            </h1>
                            <h2 className="text-h1 partner-hero__text-header text-fw-medium gap-2x">
                                Partnerships that make everything possible and drives success
                            </h2>
                            <p className="text-p1 partner-hero__text-description text-fw-regular gap-2x">
                                From global leaders, traditional resellers to leading technology
                                companies, we’ve teamed up to address unique business challenges our
                                clients face everyday, deliver best-in-class solutions, innovate
                                faster with less risk and accelerate their journey to digital
                                transformation.
                            </p>
                        </div>
                    </div>
                </section>
                <section className="partner-info">
                    <div className="container container--mid-v6">
                        <PartnerInfo />
                    </div>
                </section>
                <section>
                    <div className="container container--mid-v2">
                        <Testimonials testimonialData={clientTestimonils} />
                    </div>
                </section>
                <section className="partner-services">
                    <div className="container container--mid-v2">
                        <h2 className="text-h2 header text-clr-primary text-fw-medium">
                            Empower your business with our services
                        </h2>
                        <ServicesGrid services={partnerDetails.allContentfulServices.nodes} />
                    </div>
                </section>
                <section>
                    <div className="container container--last-v2">
                        <ContactUsBlock
                            header={"Discover opportunities to partner with Sedin"}
                            title={"Start now"}
                        />
                    </div>
                </section>
            </>
        </Layout>
    );
};

export default PartnerPage;
