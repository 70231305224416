import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ArrowIcon from "./ArrowIcon";
const PartnerInfo = () => {
    return (
        <div className="partner-info__grid">
            <Link className="text-decor-none" to="/partners/tableau">
                <div className="partner-info__grid-item">
                    <StaticImage
                        src="../images/partners/tableau-partner.png"
                        alt="tableau"
                        className="partner-logo"
                        loading="lazy"
                    />
                    <p className="text-p3 text-clr-secondary text-fw-regular">
                        This strategic partnership brings together our business intelligence service
                        offerings with Tableau’s breakthrough analytics technology for transforming
                        the future of business.
                    </p>
                    <div className="arrow">
                        <ArrowIcon direction="right" theme={"blue"} />
                    </div>
                </div>
            </Link>
            <div className="partner-info__grid-item">
                <StaticImage
                    src="../images/partners/microsoft-partner.png"
                    alt="microsoft"
                    className="partner-logo"
                    loading="lazy"
                />
                <p className="text-p3 text-clr-secondary text-fw-regular">
                    Together with Microsoft, Sedin is combining strategy and technology to empower
                    organizations to overcome challenges, drive growth & thrive in this era of
                    digital disruptions.
                </p>
            </div>
            <div className="partner-info__grid-item">
                <StaticImage
                    src="../images/partners/salesforce-partner.png"
                    alt="salesforce"
                    className="partner-logo"
                    loading="lazy"
                />
                <p className="text-p3 text-clr-secondary text-fw-regular">
                    As a trusted partner, we help clients in developing, designing, implementing &
                    driving transformational experiences using Salesforce platforms, technologies,
                    and solutions.
                </p>
            </div>
            <div className="partner-info__grid-item">
                <StaticImage
                    src="../images/partners/aws-partner.png"
                    alt="aws"
                    className="partner-logo"
                    loading="lazy"
                />
                <p className="text-p3 text-clr-secondary text-fw-regular">
                    For more than a decade, AWS and Sedin have partnered to help organizations move
                    to cloud environments, simplify cloud management, build & deploy applications at
                    scale.
                </p>
            </div>
            <div className="partner-info__grid-item">
                <StaticImage
                    src="../images/partners/opentext-partner.png"
                    alt="opentext"
                    className="partner-logo"
                    loading="lazy"
                />
                <p className="text-p3 text-clr-secondary text-fw-regular">
                    We’ve aligned with Opentext for providing Documentum Services for ECM
                    implementation, integration, upgrade, migration & support services to clients in
                    various business segments.
                </p>
            </div>
            <div className="partner-info__grid-item">
                <StaticImage
                    src="../images/partners/ibm-partner.png"
                    alt="IBM"
                    className="partner-logo"
                    loading="lazy"
                />
                <p className="text-p3 text-clr-secondary text-fw-regular">
                    A gold business partner, Sedin offers end-to-end support on IBM Maximo through
                    our next generation mobile solution- EAM360 that best meets the EAM needs of our
                    clients.
                </p>
            </div>
            <div className="partner-info__grid-item">
                <StaticImage
                    src="../images/partners/uipath-partner.png"
                    alt="uipath"
                    className="partner-logo"
                    loading="lazy"
                />
                <p className="text-p3 text-clr-secondary text-fw-regular">
                    Leveraging the power of UiPath, Sedin develops and implements “Bots” for
                    providing end-to-end RPA services from Strategy, RPA COE, discovery,
                    development, support & training.
                </p>
            </div>
            <div className="partner-info__grid-item">
                <StaticImage
                    src="../images/partners/shopify-partner.png"
                    alt="shopifyplus"
                    className="partner-logo"
                    loading="lazy"
                />
                <p className="text-p3 text-clr-secondary text-fw-regular">
                    Shopify and Sedin work together for providing solutions that help eCommerce
                    owners, entrepreneurs, merchants to start, sell, market, manage and grow their
                    online businesses.
                </p>
            </div>
            <div className="partner-info__grid-item">
                <StaticImage
                    src="../images/partners/dell-partner.png"
                    alt="dell"
                    className="partner-logo"
                    loading="lazy"
                />
                <p className="text-p3 text-clr-secondary text-fw-regular">
                    Sedin and Dell partnerships provide powerful digital solutions to clients by
                    ensuring end-to-end customer experience for better relationships.
                </p>
            </div>
            <div className="partner-info__grid-item">
                <StaticImage
                    src="../images/partners/newgen-transparent.png"
                    alt="dell"
                    className="partner-logo"
                    loading="lazy"
                />
                <p className="text-p3 text-clr-secondary text-fw-regular">
                    Newgen is the leading provider of a unified digital transformation platform with
                    native process automation, content services, and communication management
                    capabilities. "
                </p>
            </div>
        </div>
    );
};

export default PartnerInfo;
